@use "./colors" as *;

@mixin mobile {
    @media (min-width: 320px) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: 1079px) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: 1440px) {
        @content;
    }
}

@mixin line {
    content: "";
    position: absolute;
    width: 40%;
    margin: auto;
    bottom: 0;
    left: 0;
    right: 0;
    border-bottom: 4px solid $main-light;
    height: 4px;
}
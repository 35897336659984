@use "./styles/colors" as *;
@use "./styles/mixins" as *;
@import url('https://fonts.googleapis.com/css2?family=Audiowide&display=swap');

*,
::after,
::before {
  box-sizing: border-box;
}

html {
  margin: 0;
}

body {
  margin: 0;
  font-family: 'Audiowide';
}

h1 {
  font-size: 1.75rem; // 28px
  line-height: 2.25rem; // 36px
  @include tablet {
    
  }
}

h2 {
  font-size: 1.25rem; // 20px
  line-height: 1.75rem; // 28px

  @include tablet {
    font-size: 2.3rem;
    line-height: 51px;
  }

  @include desktop {
    font-size: 40px;
  }
}

h3 {
  font-size: 0.75rem; // 13px
  line-height: 1.25rem; // 20px
 
  @include tablet {
    font-size: 1rem;
    line-height: 27px;
  }

  @include desktop {
    font-size: 1.5rem;
  }
}

h4 {
  font-size: 0.688rem; // 11px
    line-height: 1rem; // 16px
  

  @include tablet {
    font-size: 0.75rem; // 12px
  line-height: 1.125rem; // 18px
  }
}

p {
  font-size: 0.8125rem;
  line-height: 1.125rem;
  font-weight: 400;

  @include tablet() {
    font-size: 1rem;
    line-height: 27px;
  }

  @include desktop {
    font-size: 1.125rem;
  }
}

button {
  font-family: 'Audiowide';
}
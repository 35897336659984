@use "../../styles/colors" as *;
@use "../../styles/mixins" as *;

.offer {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    margin: auto;
    align-items: center;
    background: url(../../assets/images/main-background.png) center/cover no-repeat;
    position: relative;

    &__gradient-top {
        position: absolute;
        height: 55px;
        border: white;
        top: -5px;
        left: 0;
        right: 0;
        background: linear-gradient(180deg, #FFFFFF 9.39%, rgba(255, 255, 255, 0) 75.61%);
    }

    
    &__heading{
        color: $main-light;
        position: relative;
        padding-bottom: 11.5px;
        margin-top: 75px;
        margin-bottom: 18px;
        @include tablet {
            margin-top: 88px;
        }
        &::after {
            @include line
        }
    }
    
    &__sub-heading{
        color: $accent;
        font-family: 'Titillium Web', sans-serif;
        margin-bottom: 40px;
        margin-top: 0;
    }
    
    &__cards{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        width: 100%;
        margin-bottom: 36px;
        padding-inline: 6%;
        position: relative;
        z-index: 1;

        @include tablet {
            flex-direction: row;
            margin-bottom: 30px;
            height: auto;
            padding-inline: 2%;

            &::before {
                content: "";
                background: white;
                position: absolute;
                top: 35%;
                left: 0;
                right: 0;
                bottom: -35%;

                @include tablet {
                    top: 78%;
                    bottom: -20%;
                }
            }
        }

        @include desktop {
            padding-inline: 13%;
        }

    }
    
    &__gradient-cards {
        position: absolute;
        height: 1080px;
        top: 276px;
        left: 0;
        right: 0;
        background: linear-gradient(180deg, #FFFFFF 15.91%, rgba(217, 217, 217, 0) 100%);
        transform: rotate(-180deg);
        z-index: 0;
        @include tablet {
            height: 377px;
            top: -60px;
        }
        @include desktop {
            height: 173px;
            top: 136px;
        }
    }

    &__card{
        flex-grow: 1;

        @include desktop {
            flex-grow: 0;
        }
    } 
}

.card {
    background-color: $accent;
    width: 100%;
    height: 311px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding-top: 17px;
    z-index: 1;
    margin-bottom: 8px;
    @include tablet {
        width: 290px;
        min-height: 375px;
        max-height: 400px;
        margin: 8px;
    }
    @include desktop {
        
    }
    &__img {
        width: 75px;
        height: auto;
        position: absolute;
        left: 0px;
        right: 0px;
        bottom: 0px;
        top: 0px;
        margin: auto;
        &-bg {
            width: 120px;
            height: 120px;
            position: relative;
            background: white;
            border-radius: 50%;
        }
    }
    &__heading {
        color: $main-light;
        font-family: 'Titillium Web', sans-serif;
        font-size: 24px;
        font-weight: 600;
        line-height: 37px;
        margin-block: 35px 5px;
        @include tablet {
            font-size: 20px;
        }
    }
    &__copy {
        color: $white;
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: center;
        width: 80%;
    }
}
        
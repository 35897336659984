@use "../../styles/colors" as *;
@use "../../styles/mixins" as *;

.contact {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding-block: 0px 48px;
    @include tablet {
        padding-block: 80px 184px;
        flex-direction: row;
        flex-wrap: wrap;
    }

    &__section {
        background: url(../../assets/images/main-background.png) center/cover no-repeat;;
        position: relative;
        margin-top: 48px;

        @include tablet {
            margin-top: 150px;
        }
    }

    &__gradient-top {
        background: linear-gradient(180deg, #FAE9C8 9.39%, rgba(255, 255, 255, 0) 75.61%);
        position: absolute;
        top: -36px;
        right: 0;
        left: 0;
        height: 50px;
        transform: rotate(-180deg);

        @include tablet {
            background: linear-gradient(180deg, #FFFFFF 9.39%, rgba(255, 255, 255, 0) 75.61%);
            top: 0px;
            height: 160px;
            transform: none;
        }
    }

    &__gradient-bottom {
        background: linear-gradient(180deg, #3D0066 37.56%, rgba(61, 0, 102, 0) 100%);
        transform: rotate(-180deg);
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 0;
        height: 150px;
        @include tablet {
            height: 317px;
        }
    }

    &__gabe-lying {
        position: absolute;
        top: -78px;
        right: 0px;
        width: 200px;
        height: 142px;
        z-index: 2;
        @include tablet {
            height: 203px;
            top: -69px;
            width: 300px;
        }
        @include desktop {
            width: 475px;
            height: 288px;
            top: -140px;
        }
    }
    
    &__left {
        background-color: $main-light--soft;
        padding: 16px 27px;
        margin-bottom: 40px;
        position: relative;
        width: 100%;

        &--gradient {
            position: absolute;
            background: linear-gradient(180deg, #FAE9C8 9.39%, rgba(255, 255, 255, 0) 75.61%);
            bottom: -30px;
            left: 0;
            right: 0;
            height: 40px;
            @include tablet {
                background: none;
            }
        }
        
        @include tablet {
            padding-top: 43px;
            padding-left: 27px;
            padding-right: 0;
            height: 475px;
            margin-bottom: 0;
            max-width: 460px;
        }
        @include desktop {
            width: 600px;
            max-width: none;
        }
    }

    &__heading {
        border-bottom: none;
    }
    &__copy {
       max-width: 600px;
       margin-bottom: 49px; 
    }

    &__comm-buttons {
        display: flex;
        justify-content: space-between;
        margin-right: auto;
        max-width: 500px;

        @include tablet {
            width: 92%;
        }
    }

    // &__comm-button {
    
    // }

    &__right {
        position: relative;
    }
}

.comm-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: initial;
    &__bg {
        width: 70px;
        height: 70px;
        position: relative;
        background: $accent;
        border-radius: 16px;
    }
    
    &__icon {
        height: 23.7px;
        width: 29.3px;
        position: absolute;
        left: 20px;
        top: 20px;
    }
    
    &__info {
        width: max-content;
    }
}
@use '../../styles/colors' as *;
@use '../../styles/mixins' as *;

.secret-nav {
    position: fixed;
    top: 0px;
    z-index: 9998;
    background: url(../../assets/images/main-background.png) center/cover no-repeat;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding: 15px 0 28px 50px;
    display: none;
    @include tablet {
        display: flex;
    }
    &::after {
        content: "";
        position: absolute;
        bottom: -1px;
        left: 0;
        right: 0;
        background: linear-gradient(180deg, #FFFFFF 34.01%, rgba(217, 217, 217, 0) 99.65%);
        transform: rotate(-180deg);
        height: 23px;
    }
    &__title {
        color: $main-light;
        font-weight: 400;
        font-size: 48px;
        line-height: 61px;
        margin-block: 0;
        text-decoration: none;
    }

    // &__ham {

    // }
}

.home-page__secret-nav img {
    margin-top: 0;
    margin-right: 50px;
    align-self: center;
}
@use "../../styles/mixins" as *;
@use "../../styles/colors" as *;

.ham {
    width: 100vw;
    height: 100vh;
    background: linear-gradient(180deg, #05A8AA 64.68%, rgba(5, 168, 170, 0) 100%);
    border-radius: 10px 0px 0px 10px;
    position: absolute;
    right: 0;
    z-index: 3;
  
    @include tablet {
      width: 25.7vw;
      height: 90vh;
      position: fixed;
      background: linear-gradient(180deg, #05A8AA 64.68%, rgba(5, 168, 170, 0) 100%);
    }

    &__icon {
      width: 38px;
      height: 19px;
      align-self: flex-end;
      margin-top: 24px;
      margin-right: 16px;

      &--exit {
        position: absolute;
        right: 0;
        width: 27.63px;
        height: 30.19px;
      }

      @include tablet {
        margin-top: 60px;
        margin-right: 50px;
      }

      &--secret {
       align-self: center;
       margin-top: 0;
       margin-right: 0; 
      }
    } 

    &__list {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      list-style: none;
      margin-top: 96px;
      margin-left: 43px;
      height: 250px;
      padding: 0;
      justify-content: space-between;
    //   @include desktop {
    //     flex-direction: row;
    //     margin-top: 0;
    //     height: auto;
    //     width: 330px;
    //     margin-bottom: 0;
    //     position: relative;
    //     left: 90px;
    //   }
    }
  
    &__item {
      font-size: 30px;
      font-weight: 400;
      line-height: 45px;
      letter-spacing: 0.20000000298023224px;
      text-align: center;
    //   @include desktop {
    //     font-size: .875rem;
    //     line-height: 1.5rem;
    //     font-weight: 600;
    //   }
    }
  
    &__link {
      text-decoration: none;
      color: $accent;
      &:hover{
        color: $main-light;
      }
    //   @include desktop {
    //   }
    }
  }
@use "../../styles/colors" as *;
@use "../../styles/mixins" as *;

h1 {
    margin: 0;
}

.home-page {
    background: url(../../assets/images/main-background.png) center/cover no-repeat;
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    height: 100%;
    justify-content: space-between;
    min-height: 265px;
    z-index: 9999;
    @include tablet {
        padding-bottom: 270px;
        padding-left: 50px;
    }
    @include desktop {
        padding-left: 18.3%;
        padding-bottom: 348px;
    }
    
    // &__hero {
        
        // }

    &__gradient {
        position: absolute;
        bottom: -1px;
        left: 0;
        right: 0;
        height: 50px;
        transform: rotate(-180deg);
        background: linear-gradient(180deg, #FFFFFF 34.01%, rgba(217, 217, 217, 0) 99.65%);
        @include tablet {
            min-height: 120px;
        }
    }
}

.hero {
    display: flex;
    align-items: center;
    padding: 2rem;
    padding-top: 30px;
    padding-bottom: 75px;
    
    @include tablet {
        padding: 0px;
    }
    @include desktop {
    }

    &__left-section {
        flex-grow: 1;   
    }

    &__main-intro {
        color: $main-light;
        font-size: 1.74rem;
        line-height: 2rem;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        margin-inline: 0;
        font-weight: 400;
        @include tablet {
            font-size: 5.5rem;
            line-height: 6.625rem;
            margin-top: 200px;
        }
        @include desktop {
            font-size: 6rem;
            line-height: 7.625rem;
            margin-top: 290px;
        }
    }

    &__sub-intro {
        color: $white;
        font-size: 0.6rem;
        line-height: 1.0rem;
        margin-top: 16px;
        @include tablet {
            font-size: 2.3rem;
            line-height: 3.1875rem;
        }
        @include desktop {
            font-size: 2.5rem;
            line-height: 3.1875rem;
            width: 57.5625rem;
        }
    }

    &__right-section {
        position: absolute;
        display: flex;
        flex-direction: column;
        right: 0;
    }

    &__gabe-sitting {
        position: absolute;
        z-index: 1;
        width: 100px;
        left: -3.4375rem;
        top: -31.25rem;
        @include tablet {
            width: 17.625rem;
            left: -6.4375rem;
            top: -26.25rem;
        }
        @include desktop {
            width: 19.9rem;
            top: -30.25rem;
        }
    }

    &__gabe-seat {
        display: none;
        position: absolute;
        background-color: $main-dark;
        width: 3rem;
        right: -5.9375rem;
        top: 2.5rem;
        height: 0px;
        border: 8px solid $main-dark;
        border-radius: 3.125rem;
        z-index: 0;
        @include tablet {
            width: 11.8087rem;
            display: inherit;
            right: 0rem;
            top: 9.5rem;
            border: 4px solid #05A8AA;
        }
        @include desktop {
            width: 13.808700000000002rem;
            right: 0.0625rem;
            top: 13.5rem;
        }
    }
    &__secret-nav {
        position: sticky;
        top: 0;
        z-index: -2;
    }
}

.secret-nav {
    position: relative;
    top: 0;
    z-index: -2;
    &__title {
        color: $main-light;
    }

    // &__ham {

    // }
}

@use "../../styles/colors" as *;
@use "../../styles/mixins" as *;

.projects {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    background: url(../../assets/images/main-background.png) center/cover no-repeat;
    position: relative;

    &__gradient-top {
        background: linear-gradient(180deg, #FFFFFF 9.39%, rgba(255, 255, 255, 0) 75.61%);
        top: 42px;
        left: 0;
        right: 0;
        height: 38px;
        position: absolute;
    }

    &__heading {
        padding-block: 90px 11.5px;
        color: $main-light;
        position: relative;
        margin-bottom: 18px;
        &::after {
            @include line;
        }
        @include tablet {
            padding-top: 88px;
        }
    }
    &__sub-heading {
        color: #393939;
        margin-block: 0 48px;

        @include tablet {
            margin-block: 0 45px;
        }
    }
        
    &__categories {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-inline: auto;
        width: 100%;
        max-width: 288px;
        flex-wrap: wrap;
        @include tablet {
            max-width: 425px;
        }
    }
        
    &__select-cat {
        height: 38px;
        padding: 6px 7px 6px;
        background: rgba(255, 206, 31, 0.5);;
        color: $accent;
        border: transparent;
        font-weight: 700;
        margin-bottom: 71px;
        border-radius: 4px;
        &:hover {
            background: #FFFFFF;
        }
        &--active {
            background: #FFFFFF;
        }
        @include tablet {
            padding: 6px 21px 9px;
            margin-bottom: 40px;
        }
    }
        
    &__laptops {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        width: 100%;
        position: relative;
        flex-direction: column;
        min-height: 1336px;
        @include tablet {
            flex-direction: row;
            padding-inline: 13%;
            min-height: 777px;
        }
        @include desktop {
            padding-inline: 7%;
            min-height: 840px;
        }
        &::after {
            content: "";
            background: white;
            position: absolute;
            bottom: -1px;
            height: 394px;
            left: 0;
            right: 0;

            @include tablet {
                height: 146px;
                bottom: -4px;
                height: 378px;
            }

            @include desktop {
                height: 205px;
                bottom: -20px;
            }
        }
    }

    &__gradient-laptops {
        position: absolute;
        height: 630px;
        top: 317px;
        left: 0;
        right: 0;
        background: linear-gradient(180deg, #FFFFFF 34.01%, rgba(217, 217, 217, 0) 99.65%);
        transform: rotate(-180deg);
        z-index: 0;
        @include tablet {
            height: 635px;
            top: 22px;
        }
    }
        
    &__project {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        z-index: 1;
        text-decoration: none;

        @include tablet {
            width: 279px;
        }

        @include desktop {
            max-height: 550px;
        }
    }
        
    &__img {
        width: 125px;
        height: 225px;
        position: relative;
        left: 6px;
        @include tablet {
            width: 200px;
            height: 375px;
        }
        @include desktop {
            height: 541px;
            width: 311px;
        }
    }
        
    &__title {
        font-family: 'Titillium Web', sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 27px;
        letter-spacing: 0em;
        text-align: left;
        text-transform: uppercase;
        color: #393939;
        margin-block: 27px 55px;

        @include tablet {
            font-size: 18px;
        }
    }
}
@use "../../styles/colors" as *;
@use "../../styles/mixins" as *;

.socials {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-inline: auto;
    align-items: center;
    @include tablet {
        width: 75%;
    }

    &__heading {
        border-bottom: none;
        color: $main-light;
        font-weight: 400;
        margin-block: 0;
        z-index: 1;
    }
    &__icons {
        list-style-type: none;
        display: flex;
        justify-content: space-between;
        padding: 0;
        margin: 0;
        z-index: 1;
        min-width: 22vmax;
        align-items: center;
        @include tablet {
            min-width: 325px;
        }
    }
    // &__item {
        
    // }
    &__link {
        text-decoration: none;
        color: inherit;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    &__text {
        font-family: 'Poppins', sans-serif;
        color: $main-light;
        &:hover {
            color: $main-dark;
        }
    }
    &__icon {
        height: 41px;
        margin: auto;
    }
}

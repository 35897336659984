@use "../../styles/colors" as *;
@use "../../styles/mixins" as *;

textarea {
    min-height: 100px;
    padding-top: 25px;
    padding-left: 5px;
}

.contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #FFDD99;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
    height: auto;
    max-width: 570px;
    z-index: 1;
    padding-bottom: 24px;
    @include tablet {
        height: 652px;
        min-width: 450px;
    }

    &__heading {
        color: $accent;
        font-size: 30px;
        line-height: 38px;
    }

    &__fields {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 90%;
        margin-inline: auto;
    }
    
    &__input-group {
        position: relative;
        width: 47%;
        margin-bottom: 30px;
        display: flex;
        flex-direction: column;

        &--email {
            width: 80%;
        }
    
        &--message {
            width: 100%;
        }
    }
    
    &__label {
        position: absolute;
        font-size: 16px;
        line-height: 24px;
        color: #C4C4C4;
        position: absolute;
        pointer-events: none;
        transform: translate(0, 23px) scale(1);
        transform-origin: top left;
        transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
        color: #6f81a5;
        font-size: 16px;
        line-height: 1;
        left: 16px; 

        &--asterisk {
            &::after {
                content: "*";
            }
        }
    } 

    &__input {
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        height: 61px;
        width: 100%;
        border: #FFDD99;
        padding-left: 16px;
    }

    &__submit {
        font-size: 16px;
        line-height: 24px;
        color: $white;
        background-color: $main-dark;
        padding: 14px 22.5px;
        border-color: $white;
        border-radius: 5px; 
    }
}

.contact-form__input-group:focus-within label {
    transform: translate(0, 7px) scale(0.8);
  }

.contact-form__input-group .filled {
transform: translate(0, 12px) scale(0.8);
}
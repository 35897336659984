@use "../../styles/colors" as *;
@use "../../styles/mixins" as *;

.about-me {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin: auto;
    width: 85%;

    &--burger {
        opacity: 0;
        @include tablet {
            opacity: initial;
        }
    }
    
    &__heading {
        color: $accent;
        margin-top: 8px;
        margin-bottom: 18px;
        padding-bottom: 11.5px;
        position: relative;

        @include tablet {
            margin-top: 24px;
        }
        
        &::after {
            @include line;
        }
    }

    &__sub-heading {
        color: #3C4761;
        margin-top: 0;
        font-family: 'Titillium Web', sans-serif;
        font-style: normal;
        font-weight: 400;
        width: fit-content;
    }

    &__copy {
        font-family: 'Poppins';
        max-width: 925px;
        margin-bottom: 25px;

        @include tablet {
            margin-bottom: 36px;
        }
    }

    &__resume-btn {
        color: $white;
        background-color: $main-dark;
        padding: 14px 22.5px;
        border-color: $white;
        border-radius: 5px;
        margin-bottom: 45px;
        text-decoration: none;

        @include tablet {
            padding: 22px 51.5px;
            margin-bottom: 64px;
        }
    }
}
 